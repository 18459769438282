<template>
    <div class="card col h-100 sidebar-col p-2 shadow-sm" id="settings-sidebar">
        <ul class="sidebar-ul d-flex flex-row text-nowrap overflow-auto gap-2" id="pills-tab" role="tablist">
            <li class="" v-for="(item, index) of data.sidebarItems" v-bind:key="index"
                v-bind:class="item.link == data.activetab ? 'active' : ''">
                <router-link class="d-flex gap-3 align-items-center" :to="item.is_live ? item.link : ''"
                    v-bind:class="item.link == data.activetab ? 'text-primary' : ''">
                    <span>{{ item.name }}</span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'TopSidebar',
    props: {
        data: Object,
    },
}
</script>