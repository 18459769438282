<template>
  <section class="card-tm" id="wallet-layout">
    <div class="container-fluid px-3">
      <div class="row gy-3 py-2 justify-content-center">
        <div class="col-12 col-lg-11">
          <TopSidebar :data="topSidebar" />
        </div>
        <div class="col-12 col-lg-11 pb-4">
          <slot></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TopSidebar from "@/components/ui/TopSidebar.vue";
export default {
  name: "WalletLayout",
  components: {
    TopSidebar
  },
  data() {
    return {
      topSidebar: {
        activetab: null,
        themeColor: 0,
        sidebarItems: [
          { name: 'Dashboard', 'icon': 'overview.svg', 'link': '/wallet/dashboard', is_live: true },
          { name: 'Spot', 'icon': 'fiat.svg', 'link': '/wallet/spot', is_live: true },
          { name: 'Hold & Earn', 'icon': 'earn.svg', link: '/wallet/staking/holdings', is_live: true },
          { name: 'Transaction History', icon: 'history.svg', link: '/wallet/transaction-history', is_live: true },
          { name: 'Orders & Trade', icon: 'history.svg', link: '/wallet/orders-history', is_live: true },
        ],
      }
    }
  },
  mounted() {
    this.topSidebar.themeColor = this.$store.getters.getTheme;
    this.topSidebar.activetab = this.$route.fullPath;

    // if (this.$route.params.slug) {
    //   this.slug = this.$route.params.slug;
    //   this.tab = this.slug.slice(this.slug.length - 3, this.slug.length);
    // }
  },
  watch: {
    "$store.getters.getTheme": function () {
      this.topSidebar.themeColor = this.$store.getters.getTheme;
    },
    $route: function () {
      this.topSidebar.activetab = this.$route.fullPath;
    }
  },
};
</script>
<style scoped>
/* .wallet-title {
  margin: 0;
  font-weight: 600;
} */

.wallet-bal-select {
  background-color: transparent;
  border: 0px;
  padding: 0px;
  border-color: var(--input-border);
  font-weight: 600;
  cursor: pointer;
}

/* .wallet-sidebar {
  background-color: var(--card-2) !important;
} */

/* .is_dark .nav-item.active,
.is_dark #sidebarItems li:hover {
  background-color: var(--card-3);
} */

/* #sidebarItems li:hover {
  background-color: var(--bg-light-yellow);
} */

/* .nav-item.active {
  background-color: var(--bg-light-yellow);
  border-radius: 0px 5px 5px 0px;
  border-left: 3px solid var(--primary) !important;
}

.nav-item.active {
  border-left: 3px solid transparent;
} */
</style>
