<template>
  <div class="row align-items-lg-center gy-lg-4 gy-2">
    <div class="col-12">
      <h6 class="title fw-600 fs-20 m-0">{{ data.heading }}</h6>
      <span class="fs-14">{{ data.subheading }}</span>
    </div>
    <div class="balance-cards col-lg-4 col-12" v-for="(item, index) of data.cards" :key="index">
      <div class="card bal-box">
        <div class="card-body p-2">
          <div class="d-flex gap-2 align-items-center">
            <div class="col-2">
              <img :src="require(`@/assets/images/${themeColor == 1 ? `icons/dark/${item.icon}` : `icons/${item.icon}`}`)"
                class="img-fluid" width="40" />
            </div>
            <div class="col">
              <span class="fs-14 fw-500 font-secondary m-0"> {{ item.name }} </span>
              <div class="d-flex flex-wrap flex-lg-column justify-content-between">
                <h6 class="balance_show m-0">{{ hideShow ? stars : $helpers.NumberFixed(item.qty, 8) }}</h6>
                <span class="sub-title fw-500">{{ hideShow ? stars : `≈ $${$helpers.NumberFixed(item.bal, 4)}` }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AvailableBalance",
  props: {
    data: Object,
  },
  data() {
    return {
      themeColor: 0,
      hideShow: false,
      loading: true,
      stars: '******',
    }
  },
  async mounted() {
    this.themeColor = this.$store.getters.getTheme;
  },
  methods: {
    changeHideShow() {
      if (!this.hideShow) {
        this.hideShow = true;
      } else {
        this.hideShow = false;
      }
    },
  },
  watch: {
    "$store.getters.getTheme": function () {
      this.themeColor = this.$store.getters.getTheme;
    },
  },
};
</script>